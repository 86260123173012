import "./App.css";
import {
  Route,
  Routes,
} from "react-router-dom";
import { withAppBar } from "./hoc/withAppBar";
import Burn from "./apps/burn/App";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Burn />} />
    </Routes>
  );
}

export default withAppBar(App);
