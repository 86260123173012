import axios from "axios";
const { REACT_APP_API_ENDPOINT_TESTNET, REACT_APP_API_ENDPOINT_MAINNET } = process.env;
const providerEnv = localStorage.getItem('providerEnv') || "TestNet" 
const apiEndpointEnvFallback = "http://localhost:5000"
let endpointEnv
if(providerEnv === "TestNet") {
  endpointEnv = REACT_APP_API_ENDPOINT_TESTNET || apiEndpointEnvFallback
} else {
  endpointEnv = REACT_APP_API_ENDPOINT_MAINNET || apiEndpointEnvFallback
}
export default {
  getApps: async (params) => {
    return (
      (await axios.get(`${endpointEnv}/api/v2/apps`, {params}))?.data ||
      0
    );
  },
  createApp: async (id, params = {}) => {
    return (
      (await axios.post(`${endpointEnv}/api/v2/launch`, { id, params }))?.data ||
      0
    ); 
  },
  deleteApp: async (id) => {
    return (
      (await axios.delete(`${endpointEnv}/api/v2/apps/${id}`))?.data ||
      0
    );
  },
};
