import React, { useEffect, useState } from "react";
import * as backend from "./build/index.main.mjs";
import Container from "react-bootstrap/Container";
import { getAccountInfo, getAmtForContract, getAsset, somethingFromSome } from "../../functions";
import appService from "../../services/appService";
import { useReach } from "../../hooks/useReach";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Spinner } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import config from "./config";
import ContractLoader from "./loaders/ContractLoader.js";
import { LocalFireDepartment, Refresh } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { bigNumberToNumber } from "@reach-sh/stdlib/CBR";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";

function App() {
  const reach = useReach();
  const [addr, setAddr] = useLocalStorage("addr", null);
  const initialState = {
    acc: null,
  };
  const [state, setState] = useState(initialState);
  const [loadingCreation, setLoadingCreation] = useState(false);
  const [loadingActivation, setLoadingActivation] = useState(false);
  const [apps, setApps] = useState(null);
  const [app, setApp] = useState(null);
  const [query, setQuery] = useState({});
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (addr) {
      handleConnect();
    }
  }, []);

  useEffect(() => {
    if (!state.acc || apps) return;
    (async () => {
      const apps = await appService.getApps({
        planId: config.PLAN_ID,
        deleted: false,
      });
      console.log(apps);
      let count = 0;
      setProgress(count / apps.length);
      let { PLAN_ID: planId } = config;
      for (let i in apps) {
        count++;
        setProgress(count / apps.length);
        let { appId } = apps[i];
        let key = `${planId}-${appId}`;
        let storedValue = localStorage.getItem(key);
        let token;
        if (storedValue) {
          token = JSON.parse(storedValue);
        } else {
          let ctc = state.acc.contract(backend, appId);
          token = somethingFromSome(
            (v) => bigNumberToNumber(v),
            0
          )(await ctc.v.token());
          localStorage.setItem(key, JSON.stringify(token));
        }
        apps[i].token = token;
      }
      setProgress(100);
      setApps(apps);
    })();
  }, [state.acc]);

  const handleConnect = async () => {
    try {
      console.log("Connecting ...");
      let acc;
      if (addr) {
        acc = await reach.connectAccount({ addr });
      } else {
        acc = await reach.getDefaultAccount();
        setAddr(acc.networkAccount.addr);
      }
      const balAtomic = await reach.balanceOf(acc);
      const bal = reach.formatCurrency(balAtomic, 4);
      const accInfo = await getAccountInfo(acc.networkAccount.addr);
      setState({
        ...state,
        acc: {
          ...acc,
          ...accInfo,
        },
        addr,
        balAtomic,
        bal,
      });
    } catch (e) {
      alert(e);
    }
  };

  const handleDisconnect = () => setState(initialState);

  const handleCreation = async () => {
    setLoadingCreation(true);
    const { info } = await appService.createApp(config.PLAN_ID);
    setApps([...apps, { appId: info }]);
    setApp(info);
    setLoadingCreation(false);
    //navigate(`/${info}`);
  };

  const handleActivation = async () => {
    if (!state.acc) return;
    setLoadingActivation(true);
    const ctc = await state.acc.contract(backend, app);
    await Promise.all([backend.Contractee(ctc, {})]);
    await appService.deleteApp(app);
    setApps(apps.filter(({ appId }) => appId !== app)); // refresh apps
    setLoadingActivation(false);
    console.log("DONE");
  };

  const handleDeletion = async () => {
    if (!app) return;
    await appService.deleteApp(app);
    setApps(apps.filter(({ appId }) => appId !== app)); // refresh apps
    console.log("DONE");
  };
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const renderInput = (input) => {
    switch (input.type) {
      case "TEXT":
        return (
          <TextField
            name={input.name}
            id={input.name}
            label={input.label}
            onChange={({ target }) =>
              setQuery({ ...query, [input.name]: target.value })
            }
            variant="outlined"
          />
        );
    }
  };

  const inputs = [
    {
      type: "TEXT",
      name: "ASSETID",
      label: "Asset ID",
    },
    {
      type: "TEXT",
      name: "AMOUNT",
      label: "Amount",
    },
  ];

  const interact = {
    Contractee: {},
    Burner: {
      getParams: () => ({
        token: parseInt(query.ASSETID),
      }),
    },
    Relay: {},
  };

  const apis = {
    burn: {
      call: (v) => {
        if (!query.AMOUNT) {
          alert("Amount required");
          return;
        }
        v(parseInt(query.AMOUNT));
      },
      render: (
        <span>
          <LocalFireDepartment />
          {` `}Burn
        </span>
      ),
    },
  };

  const views = {
    token: (v) => somethingFromSome((v) => bigNumberToNumber(v), 0)(v),
  };

  const Participants = ({ id, ctc }) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          Participants
        </Grid>
        {Object.entries(ctc.p)
          .filter(([k]) => !["Constructor", "Verifier"].includes(k))
          .map(([k, v]) => (
            <Grid item>
              <Button onClick={() => v(interact[k])}>{k}</Button>
            </Grid>
          ))}
      </Grid>
    );
  };

  const API = ({ id, ctc }) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          Api
        </Grid>
        {Object.entries(ctc.a).map(([k, v]) => (
          <Grid item>
            <Button onClick={() => apis[k].call(v)}>
              {apis[k].render || k}
            </Button>
          </Grid>
        ))}
      </Grid>
    );
  };

  const getView = async (ctc) => {
    const token = views.token(await ctc.v.token());
    console.log({ token });
    return {
      token,
    };
  };

  const View = ({ ctc }) => {
    const [state, setState] = useState(null);
    useEffect(() => {
      if (state) return;
      getView(ctc).then(setState);
    });
    return state ? (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          View
        </Grid>
        {Object.entries(state).map(([k, v]) => (
          <Grid item>
            <Item>
              {k}: {v}
            </Item>
          </Grid>
        ))}
      </Grid>
    ) : (
      "Loading view..."
    );
  };

  return (
    <>
      {progress < 100 ? (
        <LinearProgress value={progress} spacing={3} />
      ) : (
        <>
          <Grid
            container
            className="py-5"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <Grid item>
              <TextField
                size="sm"
                name="ASSETID"
                id="ASSETID"
                label="Asset Id"
                type="search"
                variant="standard"
                onChange={({ target }) =>
                  setQuery({ ...query, ASSETID: target.value })
                }
              />
            </Grid>
            <Grid item>
              <TextField
                name="AMOUNT"
                id="AMOUNT"
                label="Amount"
                type="search"
                variant="standard"
                onChange={({ target }) =>
                  setQuery({ ...query, AMOUNT: target.value })
                }
              />
            </Grid>
            <Grid
              item
              as={Button}
              onClick={async () => {
                if(!query.ASSETID) {
                  alert('Require asset id')
                  return
                }
                if(!query.AMOUNT) {
                  alert('Require ammount')
                  return
                }
                let {ASSETID: token, AMOUNT: amount} = query
                token = parseInt(token)
                amount = parseInt(amount)
                const appLst = apps.filter(el => el.token === token)
                const { asset: { params: { decimals } } } = await getAsset(token)
                if(appLst.length === 0) {
                  // TODO check if asset exists
                  const { info: appId } = await appService.createApp(config.PLAN_ID);
                  const ctc = state.acc.contract(backend, appId)
                  ctc.p.Contractee({})
                  ctc.p.Burner({
                    getParams: () => ({
                      token
                    })
                  })
                  while(somethingFromSome(v => bigNumberToNumber(v), 0)(await ctc.v.token()) === 0) {}
                  ctc.a.burn(getAmtForContract(reach)(String(amount), decimals))
                } else {
                  const ctc = state.acc.contract(backend, appLst[0].appId)
                  /*
                  ctc.p.Contractee({})
                  ctc.p.Burner({
                    getParams: () => ({
                      token
                    })
                  })
                  */
                  ctc.a.burn(getAmtForContract(reach)(String(amount), decimals))
                  console.log(ctc)
                }
              }}
              variant="outlined"
              size="large"
            >
              Burn
            </Grid>
          </Grid>
          {false &&<Container className="p-5">
            <Box sx={{ m: 5 }}>
              {!loadingCreation ? (
                <Button onClick={handleCreation} className="w-100">
                  Create
                </Button>
              ) : (
                <Button disabled className="w-100">
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Creating...
                </Button>
              )}
            </Box>
            <Box sx={{ m: 5 }}>
              <Button onClick={handleActivation} className="w-100">
                Activate
              </Button>
            </Box>
            <Box sx={{ m: 5 }}>
              <Button onClick={handleDeletion} className="w-100">
                Delete
              </Button>
            </Box>
            {apps && (
              <Box sx={{ m: 5 }}>
                <Grid container spacing={2}>
                  {apps.map((el) => (
                    <Grid item xs={4}>
                      <Item
                        style={{
                          fontWeight: el.appId === app ? "bold" : "normal",
                        }}
                        onClick={() => setApp(el.appId)}
                      >
                        {el.appId}
                      </Item>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
            {app && state.acc && (
              <>
                {inputs.map((el) => renderInput(el))}
                <ContractLoader acc={state.acc} stdlib={reach} appId={app}>
                  <hr />
                  <Participants />
                  <hr />
                  <API />
                  <hr />
                  <View />
                </ContractLoader>
              </>
            )}
          </Container>}
        </>
      )}
    </>
  );
}
export default App;
